import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'; 
import { Observable } from 'rxjs/Observable';

import { LocalStorage } from '@ngx-pwa/local-storage';
import { map, tap } from 'rxjs/operators';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
})
export class LoginRouteGuardService implements CanActivate {
currentUser
loggedin : boolean;
    constructor( private loginService:LoginService, protected localStorage: LocalStorage, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        
        return this.loginService.isLoggedIn().pipe( 
            map(user => { 
            this.currentUser = user 
            if (user && user != null) {
                return true
            }
             
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/auth/signin'], { queryParams: { returnUrl: state.url } });
             return false
             }) 
        
        )}

}