import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { LoginService } from './services/login.service';
import { environment } from 'src/environments/environment';
import { MessageService } from './services/message.service';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Clickfix Admin Dashboard';
  token
  currentUser;
  constructor(private MessagingService: MessageService,private router: Router,  private loginService: LoginService, protected localStorage: LocalStorage) { }
  

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("currentClickfixAdmin"))   
    this.token = this.localStorage.getItem('token')
    this.MessagingService.receiveMessage()
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      // this.testService.GetUsersList()

      // this.testService.AddUser(this.currentUser)
    });
  }
  logout() {
    this.loginService.logout(); 
  }
  
}
