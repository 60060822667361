<div *ngIf="!loading" class="full-container">
    <div class="row" style="margin:0!important">
        <div class="col-lg-7 col-md-6 bg--gradient">
            <div class="pad-70">
                <div class="logo">
                    <img src="assets/images/clickfix-logo.png">
                </div>
                <h2>Admin Dashboard</h2>
                <h5>User and system management</h5>
            </div>

        </div>
        <div class="col-lg-5 col-md-6">
            <div class="auth-wrapper">
                <div class="auth-content">
                    <div class="card">
                        <div class="card-body text-center">
                            <div class="mb-4">
                                <i class="feather icon-unlock auth-icon"></i>
                            </div>
                            <h3 class="mb-4">Login</h3>
                            <div class="input-group mb-3">
                                <input type="text" [(ngModel)]="phoneNumber" class="form-control" placeholder="Phone Number">
                            </div>
                            <div class="input-group mb-4">
                                <input matInput [type]="hide ? 'password' : 'text'" class="form-control" [(ngModel)]="password" placeholder="Password">
                                <div class="input-group-append" (click)="hide = !hide">
                                    <a href="#" class="input-group-text" id="basic-addon2">
                                        <mat-icon matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </a>
                                </div>
                            </div>

                            <button class="btn btn-primary mb-4" (click)="login()">Login</button>
                            <alert></alert>
                            <!-- <p class="mb-2 text-muted">Forgot password? <a
                                    [routerLink]="['/auth/reset-password']">Reset</a></p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
