import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/main/ui-elements/alert';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { MessageService } from 'src/app/services/message.service';
@Component({
  selector: 'app-auth-signin',
  templateUrl: './auth-signin.component.html',
  styleUrls: ['./auth-signin.component.scss']
})
export class AuthSigninComponent implements OnInit {
  password;
  phoneNumber;
  deviceType;
  hide = true;
  loading = true;
  returnUrl: string;
  constructor(protected alertService: AlertService, private loginService: LoginService ,  private route: ActivatedRoute , private router: Router,protected localStorage: LocalStorage, private messageService: MessageService) {

  }
  options = {
    autoClose: true,
    keepAfterRouteChange: false
};
  async ngOnInit() {
    // this.loginService.logout();
    this.returnUrl = this.route.snapshot.queryParams['index'] || '/';
    if(localStorage.getItem('token')){
      this.router.navigateByUrl(this.returnUrl);
    }
    const token =  await this.messageService.requestPermission()
    // if(token) {
    this.loading = false
    // }
  }

 login()
 {

  this.loading = true

  this.loginService.Login({"phoneNumber": this.phoneNumber, "password":this.password,"role":1, "deviceType": 4, "deviceTokens": localStorage.getItem('token') })
  .subscribe(
      data => {
          if (data === undefined && localStorage.getItem('token') == undefined) {
            this.loading = false;
          }
          else {
            localStorage.setItem('jwt', data.token)
            localStorage.setItem('phoneNumber', this.phoneNumber)
            if(this.phoneNumber == "+9615459183"){
              this.router.navigateByUrl('/dashboard/requests/upcoming');
            } else {
              this.router.navigateByUrl('/dashboard');
            }
          }
      },
      error => {
        if(error.status === 401)
        {
          this.alertService.error('Incorrect password', this.options)
          return
        }
        if(error.status == 422)
        {
          this.alertService.error('Incorrect credentials', this.options)
          alert("incorrect credentials")
          this.router.navigateByUrl('/auth/signin');
          window.location.reload()
          return
        }

        if(error.status === 403)
        {
          this.alertService.error('Unauthorised user. Please ask the administrator to sign you up.', this.options)
          return
        }
        else{
          this.alertService.error('Connection lost please try again in few seconds.', this.options)

        }
          console.log("ERROR SIGNING IN ", error.status)
      });
 }


}
