import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-snackbarnotification',
  templateUrl: './snackbarnotification.component.html',
  styleUrls: ['./snackbarnotification.component.scss']
})
export class SnackbarNotificationComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBar: MatSnackBar) { }
  ngOnInit() {
  }
  public dismissSnackbar(): void {
    this.snackBar.dismiss();
}

}
