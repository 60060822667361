

import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoadingBarService {

  private _configSubject$: BehaviorSubject<LoadingBarConfig> = new BehaviorSubject<LoadingBarConfig>({
    value: 0,
    bufferValue: 0,
    visible: false,
    mode: 'indeterminate'
  });

  public config$: Observable<LoadingBarConfig> = this._configSubject$.asObservable();

  public constructor(private _router: Router) {
    this._init();
  }





  public show(): void {
    this.updateConfig({ visible: true });
  }

  public hide(): void {
    this.updateConfig({ visible: false });
  }

  private updateConfig(updates: Partial<LoadingBarConfig>) {
    this._configSubject$.next({ ...this._configSubject$.value, ...updates });
  }

  private _init(): void {

    this._router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.show();
    });

    this._router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel
        )
      )
      .subscribe(() => {
        this.hide();
      });
  }

}

export interface LoadingBarConfig {
  value: number;
  visible: boolean;
  bufferValue: number;
  mode: ProgressBarMode,
}

