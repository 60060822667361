import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class LoginService {
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}

  isLoggedIn() {
    if(localStorage.getItem("currentClickfixAdmin"))
    {
      this.loggedIn.next(JSON.parse(localStorage.getItem("currentClickfixAdmin")))
    }
    return this.loggedIn.asObservable()
 }

 constructor(public http: HttpClient, private router: Router
 ) {

  }

 httpOptions = {
  headers: new HttpHeaders({
    'content-type': 'application/json',
    'accept': 'application/json',
    'Cache-Control' : 'no-cache',
    'Access-Control-Allow-Origin' :'*',
    'source':'1'
  })
  // params: {skip: 50}
};

  /** POST: POST a login  */
  Login (cridentials){

   return this.http.post(environment.baseUrl +`login`, cridentials, this.httpOptions).pipe(
     tap(user => {
       if (user) {
         // store user details and jwt token in local storage to keep user logged in between page refreshes
         localStorage.setItem('currentClickfixAdmin', JSON.stringify(user));
         console.log(user)
     }
       return user;
   }),
     catchError(this.handleError<any>('LOGIN'))
   );
 }

logout() {
 this.loggedIn.next(false);
 this.router.navigate(['/auth/signin']);
   // remove user from local storage to log user out
   localStorage.removeItem('currentClickfixAdmin');
   localStorage.removeItem('token');
   localStorage.removeItem('language');
}

signup(user:object){
 return this.http.post(environment.baseUrl +`signup`, user, this.httpOptions).pipe(
   tap(user => {
     if (user) {
       // store user details and jwt token in local storage to keep user logged in between page refreshes
       localStorage.setItem('currentClickfixAdmin', JSON.stringify(user));
   }
     return user;
 }),
   catchError(this.handleError<any>('Signup'))
 );}
/**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
 private handleError<T> (operation = 'operation', result?: T) {
   return (error: any): Observable<T> => {

     // TODO: send the error to remote logging infrastructure
     console.error(error); // log to console instead
    throw error
     // TODO: better job of transforming error for user consumption

     // Let the app keep running by returning an empty result.
     return of(result as T);
   };
 }

 getToken(){
  return JSON.parse(localStorage.getItem('currentClickfixAdmin')).token
 }

}
