<div fx-layout="column">
    <div fxFlex="95">
        {{ data }}
    </div>
    <audio autoplay>
        <source src="/assets/juntos-607.mp3" type="audio/ogg">
    </audio>
    <!-- <div fxFlex="5"> -->
    <!-- <span (click)="dismissSnackbar();">x</span> -->
    <button type="button" class="btn btn-sm btn-dark" (click)="dismissSnackbar();">Dismiss</button>
    <!-- </div> -->
</div>
