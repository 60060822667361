import { Component, DoCheck, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { DattaConfig } from '../../../../../app-config';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from "@angular/common";
import { Inject } from '@angular/core';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public dattaConfig: any;
  public email: string;
  public id
  public currentUser
  constructor(@Inject(DOCUMENT) private _document: any, private translate: TranslateService, config: NgbDropdownConfig, public loginService: LoginService, private router: Router) {
    config.placement = 'bottom-right';
    this.visibleUserList = false;
    this.chatMessage = false;
    this.dattaConfig = DattaConfig.config;
    let  lang = localStorage.getItem("language") ?  localStorage.getItem("language") :'en';
    localStorage.setItem("language", lang);
    translate.setDefaultLang(lang);
    this._document.documentElement.dir=  localStorage.getItem("language")  == 'ar' ? 'rtl' : 'ltr'; 

  }

  ngOnInit() {
 
    this.currentUser  =  JSON.parse(localStorage.getItem('currentClickfixAdmin'));
    
    this.email = this.currentUser?.fullName
    this.id = this.currentUser?._id
  }

  onChatToggle(friend_id) {
    this.friendId = friend_id;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('datta-rtl')) {
      this.dattaConfig['rtl-layout'] = true;
    } else {
      this.dattaConfig['rtl-layout'] = false;
    }
  }
  logout() {
    this.loginService.logout();
  }
  editProfile() {
    this.router.navigate(['/dashboard/admins/edit'], { queryParams: { id: this.id } });
  }
  changePassword()
  {
    this.router.navigate(['/dashboard/admins/change-password'], { queryParams: { id: this.id } });
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    let oldLang = localStorage.getItem("language");
    localStorage.setItem("language", language);
    let newLang = localStorage.getItem("language");
    this._document.documentElement.dir= localStorage.language == 'ar' ? 'rtl' : 'ltr';
    if(oldLang !== newLang){
      location.reload();
    }
  }

}
