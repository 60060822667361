import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private authService: LoginService) { }
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if(!request.url.includes('signup') && !request.url.includes('login') && !request.url.includes('firebasestorage'))
    {
      const token = this.authService.getToken();
      if (token) {
      request = request.clone({
        setHeaders:
        {
          'Authorization': token,
          'content-type': 'application/json',
          'accept': 'application/json',
          'Cache-Control': 'no-cache',
          'Access-Control-Allow-Origin': '*',
          'source': '1'

        },
      });
      
    }


    else {
      this.authService.logout()

    }
  }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && (error.status === 401)) {
          return next.handle(request);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
