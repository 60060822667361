<ul class="navbar-nav">

    <p class="my-1">Version 1.0.3</p>

    <li>
        <div class="dropdown drp-user" ngbDropdown placement='auto'>
            <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
                <span>Hey {{email}}</span>
            </a>

            <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
                <div class="pro-head">
                    <img src="assets/images/user/avatar-2.jpg" class="img-radius" alt="User-Profile-Image">
                    <span>{{email}}</span>
                </div>
                <ul class="pro-body">
                    <!-- <li (click)="editProfile()"><a href="javascript:" class="dropdown-item"><i class="feather icon-user"></i> Profile</a></li>
                    <li (click)="changePassword()"><a href="javascript:" class="dropdown-item"><i class="feather icon-lock"></i>Change password</a></li> -->

                    <li (click)="logout()"><a href="javascript:" class="dropdown-item"><i class="feather icon-log-out" ></i>Logout</a></li>
                </ul>
            </div>
        </div>
    </li>
</ul>