import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [

  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    icon: 'feather icon-home',
    children: [
      {
        id: 'analytics',
        title: 'Analytics',
        type: 'item',
        icon: 'feather icon-bar-chart-2',
        url: '/dashboard/analytics'
      }
    ]
  },

  {
    id: 'Requests',
    title: 'Requests',
    type: 'group',
    icon: 'icon-navigation',
    children: [


      {
        id: 'requests',
        title: 'Upcoming Requests',
        type: 'item',
        url: '/dashboard/requests/upcoming',
        icon: 'feather icon-box',
        classes: 'nav-item',
      },

      {
        id: 'requests',
        title: 'Requests History',
        type: 'item',
        url: '/dashboard/requests/history',
        icon: 'feather icon-rotate-ccw',
        classes: 'nav-item',
      },

      {
        id: 'requests',
        title: 'Yearly Requests',
        type: 'item',
        url: '/dashboard/requests/yearly',
        icon: 'feather icon-calendar',
        classes: 'nav-item',
      },
      {
        id: 'HyperSplits',
        title: 'Hyper Split',
        type: 'item',
        icon: 'icon-navigation',
        url: '/dashboard/splits'
        },
        {
          id: 'Invoices',
          title: 'Invoices',
          type: 'item',
          icon: 'icon-navigation',
          url: '/dashboard/invoices'
          },

    ]
  },
  {
    id: 'Subscribtions',
    title: 'Subscribtions',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'Active requests',
        title: 'Active requests',
        type: 'item',
        url: '/dashboard/requests/subs',
        icon: 'feather icon-box',
        classes: 'nav-item',
      },
      {
        id: 'finished requests',
        title: 'Finished requests',
        type: 'item',
        url: '/dashboard/requests/subs-history',
        icon: 'feather icon-rotate-ccw',
        classes: 'nav-item',
      },
      {
        id: 'active subscribtions',
        title: 'active subscribtions',
        type: 'item',
        url: '/dashboard/users/active-subs',
        icon: 'feather icon-file-plus',
        classes: 'nav-item',
      },
      {
        id: 'predefined packages',
        title: 'Predefined packages',
        type: 'item',
        url: '/dashboard/users/packages',
        icon: 'feather icon-users',
        classes: 'nav-item',
      },
      {
        id: 'Build your package',
        title: 'Build your package',
        type: 'item',
        url: '/dashboard/users/byop-config',
        icon: 'feather icon-users',
        classes: 'nav-item',
      },
    ]
  },
  {
    id: 'workers',
    title: 'Workers',
    type: 'group',
    icon: 'icon-navigation',
    children: [

      {
        id: 'workers',
        title: 'Workers',
        type: 'item',
        icon: 'feather icon-user',
        url: '/dashboard/workers',
        classes: 'nav-item',
      },
      {
        id: 'worker-map',
        title: 'Workers Map',
        type: 'item',
        icon: 'feather icon-map',
        url: '/dashboard/workers-map',
        classes: 'nav-item',
      },
    ]
    },

    {
      id: 'Services',
      title: 'Services & Providers',
      type: 'group',
      icon: 'icon-navigation',
      children: [

        {
          id: 'services',
          title: 'Services',
          type: 'item',
          icon: 'feather icon-list',
          url: '/dashboard/services',
          classes: 'nav-item',
        },
        {
          id: 'service-providers',
          title: 'Service Providers',
          type: 'item',
          icon: 'feather icon-users',
          url: '/dashboard/service-providers',
          classes: 'nav-item',
        },
      ]
      },


  {
    id: 'Accounts',
    title: 'Accounts',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'admins',
        title: 'Admins',
        type: 'item',
        icon: 'feather icon-user-check',
        url: '/dashboard/admins',
        classes: 'nav-item',
      },

      {
        id: 'users',
        title: 'Users',
        type: 'item',
        icon: 'feather icon-user',
        url: '/dashboard/users',
        classes: 'nav-item',
      },

    ]
    },
    {
      id: 'Settings',
      title: 'Settings',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'settings',
          title: 'Main Settings',
          type: 'item',
          icon: 'feather icon-settings',
          url: '/dashboard/settings',
          classes: 'nav-item',
        },
      ]
      },
      {
        id: 'Promocodes',
        title: 'Promo codes',
        type: 'item',
        icon: 'icon-navigation',
        url: '/dashboard/promocodes'
        },


];

const LimitedNavigationItems = [
  {
    id: 'Requests',
    title: 'Requests',
    type: 'group',
    icon: 'icon-navigation',
    children: [


      {
        id: 'requests',
        title: 'Upcoming Requests',
        type: 'item',
        url: '/dashboard/requests/upcoming',
        icon: 'feather icon-box',
        classes: 'nav-item',
      },

      {
        id: 'requests',
        title: 'Requests History',
        type: 'item',
        url: '/dashboard/requests/history',
        icon: 'feather icon-rotate-ccw',
        classes: 'nav-item',
      },
    ]
  },
  {
    id: 'workers',
    title: 'Workers',
    type: 'group',
    icon: 'icon-navigation',
    children: [

      {
        id: 'workers',
        title: 'Workers',
        type: 'item',
        icon: 'feather icon-user',
        url: '/dashboard/workers',
        classes: 'nav-item',
      },
    ]
    },

]


@Injectable({
    providedIn: 'root'
})
export class NavigationItem {
  get() {
    let role = JSON.parse(localStorage.getItem('currentClickfixAdmin'))?.role
    let phoneNumber = JSON.parse(localStorage.getItem('currentClickfixAdmin'))?.phoneNumber
    if(phoneNumber == "+9615459183"){
      return NavigationItems
    } else if (role == 2) {
      return LimitedNavigationItems
    } else if ((role == 1) && (phoneNumber != "+9615459183")){
        let permissions = JSON.parse(localStorage.getItem('currentClickfixAdmin'))?.permissions
        if(permissions){
          if(permissions.length > 0){
            let permissionList = [
              {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'group',
                icon: 'feather icon-home',
                children: [
                  {
                    id: 'analytics',
                    title: 'Analytics',
                    type: 'item',
                    icon: 'feather icon-bar-chart-2',
                    url: '/dashboard/analytics'
                  }
                ]
              }
            ] as any
            if(permissions.includes('requests.access')){
              permissionList.push({
                id: 'Requests',
                title: 'Requests',
                type: 'group',
                icon: 'icon-navigation',
                children: [
                  {
                    id: 'requests',
                    title: 'Upcoming Requests',
                    type: 'item',
                    url: '/dashboard/requests/upcoming',
                    icon: 'feather icon-box',
                    classes: 'nav-item',
                  },
            
                  {
                    id: 'requests',
                    title: 'Requests History',
                    type: 'item',
                    url: '/dashboard/requests/history',
                    icon: 'feather icon-rotate-ccw',
                    classes: 'nav-item',
                  },
            
                  {
                    id: 'requests',
                    title: 'Yearly Requests',
                    type: 'item',
                    url: '/dashboard/requests/yearly',
                    icon: 'feather icon-calendar',
                    classes: 'nav-item',
                  }
            
                ]
              })
            }
            if(permissions.includes('workers.access')){
              permissionList.push({
                id: 'workers',
                title: 'Workers',
                type: 'group',
                icon: 'icon-navigation',
                children: [
            
                  {
                    id: 'workers',
                    title: 'Workers',
                    type: 'item',
                    icon: 'feather icon-user',
                    url: '/dashboard/workers',
                    classes: 'nav-item',
                  },
                  {
                    id: 'worker-map',
                    title: 'Workers Map',
                    type: 'item',
                    icon: 'feather icon-map',
                    url: '/dashboard/workers-map',
                    classes: 'nav-item',
                  },
                ]
                })
            }
            if(permissions.includes('services.access')){
              permissionList.push({
                id: 'services',
                title: 'Services',
                type: 'item',
                icon: 'feather icon-list',
                url: '/dashboard/services',
                classes: 'nav-item',
              })
            }
            if(permissions.includes('sp.access')){
              permissionList.push({
                id: 'service-providers',
                title: 'Service Providers',
                type: 'item',
                icon: 'feather icon-users',
                url: '/dashboard/service-providers',
                classes: 'nav-item',
              })
            }
            if(permissions.includes('users.access')){
              permissionList.push({
                id: 'users',
                title: 'Users',
                type: 'item',
                icon: 'feather icon-user',
                url: '/dashboard/users',
                classes: 'nav-item',
              })
            }
            if(permissions.includes('promo.access')){
              permissionList.push({
                id: 'Promocodes',
                title: 'Promo codes',
                type: 'item',
                icon: 'icon-navigation',
                url: '/dashboard/promocodes'
                })
            }
            return permissionList
          } else {
            return []
          }
        } else {
          return []
        }
    }
    // return NavigationItems;
  }
}
