import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
// import { MessageService } from './message.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

  constructor(public http: HttpClient) { }
  baseUrl = environment.baseUrl;
  androidUsers = 0;
  iphoneUsers = 0;
  /** GET user by id. Will 404 if id not found */
  getUser(id: string): Observable<User> {
    const url = `${this.baseUrl + 'users/user'}/${id}`;
    return this.http.get<User>(url).pipe(
      tap(_ => console.log(`fetched user id=${id}`)),
      catchError(this.handleError<User>(`getUser id=${id}`))
    );
  }

  getPackages(){
    const url = `${this.baseUrl + 'categories/predefined-packages'}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched user`)),
      catchError(this.handleError<User>(`getUser`))
    );
  }

  getBPackages(){
    const url = `${this.baseUrl + 'categories/packages-sub'}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched user`)),
      catchError(this.handleError<User>(`getUser`))
    );
  }

  updateBPackages(body: any){
    let admin = JSON.parse(localStorage.getItem('currentClickfixAdmin'))

    const url = `${this.baseUrl + 'categories/packages-sub'}`;
    return this.http.put<any>(url, body, {headers: {
      'Authorization': admin.token
  },
  }).pipe(
      tap(_ => console.log(`fetched user`)),
      catchError(this.handleError<User>(`getUser`))
    );
  }


  updatePrepackage(id, body){
    const url = `${this.baseUrl + 'categories/prepackages-subscription-schema/' + id}`;
    return this.http.put<any>(url, body).pipe(
      tap(_ => console.log(`fetched user`)),
      catchError(this.handleError<User>(`getUser`))
    );
  }

  createPrepackage(body){
    const url = `${this.baseUrl + 'categories/prepackages-subscription-schema'}`;
    return this.http.post<any>(url, body).pipe(
      tap(_ => console.log(`fetched user`)),
      catchError(this.handleError<User>(`getUser`))
    );
  }

  getPackage(id: string){
    const url = `${this.baseUrl + 'categories/predefined-packages/' + id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched user`)),
      catchError(this.handleError<User>(`getUser`))
    );
  }

  GetOneSub(userid: string, id:string){
    const url = `${this.baseUrl + 'categories/my-subscriptions/' + userid + '/' + id}`
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched user id=${id}`)),
      catchError(this.handleError<User>(`getUser id=${id}`))
    );
  }

  getActiveSubs(): Observable<any> {
    const url = `${this.baseUrl + 'categories/all-subscriptions'}`;
    return this.http.get<any[]>(url).pipe(tap(users => {console.log(users)}))
  }
  getCredits(id: string): Observable<User>{
    const url = `${this.baseUrl + 'finance/credits'}/${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched credits id=${id}`)),
      catchError(this.handleError<User>(`getCredits id=${id}`))
    );
  }

  PostCredits(credits: any): Observable<any> {

    return this.http.post(this.baseUrl + `finance/credits`, credits).pipe(
      catchError(this.handleError<any>('createUser'))
    );
  }


  updateUser(updateReq): Observable<any> {

    return this.http.put(this.baseUrl + `admin/user`, updateReq).pipe(
      tap(_ => {
        console.log('updated');
      }),
      catchError(this.handleError<any>('updateMOF'))
    );
  }

  updateUserAdmin(updateReq): Observable<any> {

    return this.http.put(this.baseUrl + `admin/user`, updateReq).pipe(
      tap(_ => {
        console.log('updated');
      }),
      catchError(this.handleError<any>('updateMOF'))
    );
  }
  updateWorker(user: User): Observable<any> {
    return this.http.put(this.baseUrl + `users/user`, user).pipe(
      tap(_ => {
        console.log("Updated Worker");
      }),
      catchError(this.handleError<any>('updateUser'))
    );
  }

  GetUsers() {
    return this.http.get<User[]>(this.baseUrl + 'users/users?subs=false').pipe(
      tap(users => {
        if (users) {
          localStorage.setItem('users', JSON.stringify(users));
        }
      }),
      catchError(this.handleError('GetUsers', []))
    )
  }

  GetSubUsers() {
    return this.http.get<User[]>(this.baseUrl + 'users/users?subs=true').pipe(
      tap(users => {
        if (users) {
          localStorage.setItem('susers', JSON.stringify(users));
        }
      }),
      catchError(this.handleError('GetUsers', []))
    )
  }

  GetAdmins(){
    return this.http.get<User[]>(this.baseUrl + '/users/admins').pipe(
      catchError(this.handleError('GetAdmins', []))
    )
  }


  UpdateSpPassword(id: string, password: string){
    return this.http.put<User[]>(this.baseUrl + 'admins/change-sp-password/' + id, {password: password}).pipe(
      catchError(this.handleError('UpdateSp', []))
    )
  }

  GetPartners(){
    return this.http.get<User[]>(this.baseUrl + '/users/partners').pipe(
      catchError(this.handleError('GetPartners', []))
    )
  }

  GetPartnersFiltered(category: String){
    return this.http.get<User[]>(this.baseUrl + `/users/partners?filtered=${category}`).pipe(
      catchError(this.handleError('GetPartners', []))
    )
  }

  GetWorkers(){
    return this.http.get<User[]>(this.baseUrl + '/users/workers').pipe(
      catchError(this.handleError('GetWorkers', []))
    )
  }

  
  deleteUser(id: string){
    return this.http.delete<User[]>(this.baseUrl + '/users/' + id).pipe(
      catchError(this.handleError('Delete user', []))
    )
  }


  GetWorkersLocation() {
    return this.http.get<User[]>(this.baseUrl + 'workers/locations').pipe(
      tap(workers => {
        if (workers) {
          localStorage.setItem('locationWorkers', JSON.stringify(workers));
        }
      }),
      catchError(this.handleError('GetWorkersLocation', []))
    )
  }

  PostUser(user: User): Observable<any> {

    return this.http.post(this.baseUrl + `admin/user`, user).pipe(
      catchError(this.handleError<any>('createUser'))
    );
  }

  UpdateWorkerr(id, object): Observable<any> {

    return this.http.put(this.baseUrl + `users/updateworker/${id}`, object).pipe(
      catchError(this.handleError<any>('update worker'))
    );
  }

  GetServiceProviders() {
    return this.http.get<User[]>(this.baseUrl + 'partners').pipe(
      tap(users => {
        if (users) {
          localStorage.setItem('users', JSON.stringify(users));
          localStorage.setItem('partners', JSON.stringify(users));
        }
      }),
      catchError(this.handleError('GetPartners', []))
    )
  }

  GetProfessions() {
    return this.http.get<object[]>(this.baseUrl + 'professions').pipe(
      tap(users => {
      }),
      catchError(this.handleError('GetPartners', []))
    )
  }


  GetUsersPhoneType() {
    
    return this.http.get<User[]>(this.baseUrl + 'users').pipe(
      tap(users => {
        if (users) {
          users.forEach(user => {
            if(user.deviceTokens.length>0 && user.deviceTokens[user.deviceTokens.length-1].deviceType === 1)
            {
              this.iphoneUsers++;
            }
            else if(user.deviceTokens.length>0  && user.deviceTokens[user.deviceTokens.length-1].deviceType === 2)
            {
              this.androidUsers++
            }
           
          });
          
          localStorage.setItem('iphoneUsers',this.iphoneUsers.toString())
          localStorage.setItem('androidUsers',this.androidUsers.toString())
          
        }
        
      }),
      catchError(this.handleError('GetUsers', []))
    )
  }


  /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
    if(error.status == 422){
      alert("error: " + error.error?.message);
      return 
    }
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  // /** Log a HeroService message with the MessageService */
  // private log(message: string) {
  //   this.messageService.add('HeroService: ' + message);
  // }
}