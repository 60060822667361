<ng-container   *ngIf="loadingBarService.config$ | async as config">
    <ng-container *ngIf="config.visible">
        <div id="http-loader">
            <div class="loader-bg" >
                <!-- material-line -->
                <div class="sk-line-material" [class.colored]="!backgroundColor" *ngIf="spinner === Spinkit.skLine">
                    <div class="sk-child sk-bounce1" [style.background-color]='backgroundColor'></div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>