// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  secret :'9L`\R`nqXTG${W[=',
  baseUrl : 'https://clickfix.devsy.tech:3000/v1/',
  // baseUrl : 'http://localhost:3000/v1/',
  firebaseConfig: {
    apiKey: "AIzaSyCWPy49ha8zspEuc0UvI07ZO1K2hSuD6x4",
    authDomain: "fam-sal.firebaseapp.com",
    databaseURL: "https://fam-sal.firebaseio.com",
    projectId: "fam-sal",
    storageBucket: "fam-sal.appspot.com",
    messagingSenderId: "85023399252",
    appId: "1:85023399252:web:5c802c1efd2d837dc63cc1",
    measurementId: "G-V961MBPD0G"
  }
  // firebaseConfig : {
  //   apiKey: "AIzaSyA5p880AD77xjvaEyqkYBDjHSBrqgZOla8",
  //   authDomain: "fam-sal.firebaseapp.com",
  //   databaseURL: "https://fam-sal.firebaseio.com",
  //   projectId: "fam-sal",
  //   storageBucket: "fam-sal.appspot.com",
  //   messagingSenderId: "85023399252",
  // },
}



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
