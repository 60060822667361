import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component'; 
import { LoginRouteGuardService as AuthGuard } from './services/login-route-guard.service';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: localStorage.getItem('phoneNumber') == "+9615459183" ? 'dashboard/requests/upcoming' : 'dashboard/services',
        pathMatch: 'full',
      },
      
      {
        path: 'dashboard',
        loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
      }, 
    ]
  },
  { path: 'reset-password', loadChildren: () => import('./main/dashboard/admins/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
 
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
