declare var require: any
import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging'
import { BehaviorSubject } from 'rxjs'

import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { LocalStorage } from '@ngx-pwa/local-storage';
const firebase = require('firebase/app');

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
require('firebase/firestore');
require('firebase/messaging');
import 'firebase/messaging';


import { UserService } from './user.service';
import { User } from '../models/user';


import { SnackbarNotificationComponent } from '../main/extra/snackbarnotification/snackbarnotification.component';


@Injectable({
    providedIn: 'root'
})
export class MessageService {
  currentMessage = new BehaviorSubject(null)
  public messaging;
  //firebase = require('firebase');

  constructor(private angularFireMessaging: AngularFireMessaging ,private db: AngularFireDatabase, private afAuth: AngularFireAuth, private userService: UserService, public snackBar: MatSnackBar) {

    
  this.angularFireMessaging.messages.subscribe(
    (payload: any) => {
    payload.onMessage = payload.onMessage.bind(payload)
    payload.onTokenRefresh = payload.onTokenRefresh.bind(payload)
    console.log("new message received. ", payload);
    let horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    try {
        this.snackBar.open(payload.body);
        let audio = new Audio("https://www.w3schools.com/jsref/horse.mp3")
        audio.play()
        this.snackBar.openFromComponent(SnackbarNotificationComponent, {
          data: payload.notification.body,
          horizontalPosition: horizontalPosition,
          verticalPosition: verticalPosition
        });
        this.currentMessage.next(payload);
    } catch (e) {
      console.log('Unable to receive Messaing', e);
    }
    })

    // try {
    //   console.log("firebase.messaging();", firebase.messaging());
    //   this.messaging = firebase.messaging();
    //   console.log('Instantiate Firebase Messaging');
    // } catch (e) {
    //   console.log('Unable to Instantiate Firebase Messaging', e);
    // }
  }

  messages: string[] = [];
  webUser: User;

  
  add(message: string) {
    this.messages.push(message);
  }

  clear() {
    this.messages = [];
  }

  updateToken(token) {
    try {
      this.webUser.deviceTokens.push(token);
    } catch (e) {
      console.log("error update token", e);
    }
  }
  requestToken(): void {
    this.angularFireMessaging.requestToken.subscribe({
        next: token => {
          console.log(token)
        },
        error: err => {
          console.error('Fetching FCM token failed: ', +err)
        }
    })
}
  requestPermission(){
    return new Promise((reslove, reject) => {
        this.angularFireMessaging.requestToken.subscribe((token) => {
          console.log(token);
          localStorage.setItem("token", token);
          reslove(token);
          //this.updateToken(token);
        },
          (err) => {
          console.error('Unable to get permission to notify.', err);
        }
      )
    });
  }


  getPermission() {
    try {
      this.messaging.requestPermission()

        .then(() => {
          
          return this.messaging.getToken()

        })
        .then(token => {
         
          localStorage.setItem("token", token);
          console.log(token)
          this.updateToken(token);
        })
        .catch((err) => {
          console.log('Unable to get permission to notify.', err);
        });
    } catch (e) {
      console.log('Unable to get permission to notify.', e);
    }
  }

  
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
      console.log("new message received. ", payload);
      let horizontalPosition: MatSnackBarHorizontalPosition = 'end';
      let verticalPosition: MatSnackBarVerticalPosition = 'top';
      try {
          console.log("Message received, ", payload);
          this.snackBar.open(payload.body);
          this.snackBar.openFromComponent(SnackbarNotificationComponent, {
            data: payload.notification.body,
            horizontalPosition: horizontalPosition,
            verticalPosition: verticalPosition
          });
          this.currentMessage.next(payload);
      } catch (e) {
        console.log('Unable to receive Messaing', e);
      }
      })
    }

  // receiveMessage() {
    // let horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    // let verticalPosition: MatSnackBarVerticalPosition = 'top';
    // try {
    //   this.angularFireMessaging.messages.subscribe((payload) => {
    //     console.log("Message received, ", payload);
    //     this.snackBar.open(payload.toString());
    //     this.snackBar.openFromComponent(SnackbarNotificationComponent, {
    //       data: payload,
    //       horizontalPosition: horizontalPosition,
    //       verticalPosition: verticalPosition
    //     });
    //     this.currentMessage.next(payload);
    //   });
    // } catch (e) {
    //   console.log('Unable to receive Messaing', e);
    // }
  // }
}

